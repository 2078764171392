<template>
  <div class="works">
    <left-menu current="income" />
    <div class="main">
      <div class="success">
        <img src="@/assets/successIcon.jpg" class="icon" />
        <div class="title">确认成功</div>
        <div class="btns">
          <div class="back btn" @click="$router.push('/income')">
            返回我的收益
          </div>
          <div class="goon btn" @click="$router.push('/incomeList')">去申请提现</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue'
export default {
  components: {
    LeftMenu
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  }
}
</script>
<style scoped lang="scss">
@import "../styles/initialization.scss";
@import "../styles/works.scss";
.success {
 margin-top: 231px;
  background-color: #ffffff;
  height: 100vh;
  display: flex;
//   justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon {
    width: 100px;
    height: 100px;
  }
  .title {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: CENTER;
    color: #666666;
    margin-bottom: 40px;
  }

  .btns {
    display: flex;
    align-items: center;
    .btn {
      width: 124px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
    }
    .back {
      background: #efefef;
      color: #ff004d;
    }
    .goon {
      background: #ff004d;
      color: #ffffff;
      margin-left: 21px;
    }
  }
}
</style>